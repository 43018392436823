<script>
import api from "@/api";
export default {
  props: ["group"],
  data() {
    return {
      newManager: "",
      managers: [],
    };
  },
  methods: {
    addManager() {
      this.$emit("addManager", {
        email: this.newManager,
        groupId: this.group.id,
      });
      this.newManager = "";
      this.loadManagers();
    },
    removeManager(email) {
      this.$emit("removeManager", {
        email: email,
        groupId: this.group.id,
      });
      this.loadManagers();
    },
    loadManagers() {
      return api.Util.axios
        .get(`/groups/${this.group.id}/`)
        .then((response) => {
          this.managers = response.data.user;
        });
    },
  },
  computed: {
    email() {
      if (this.$store.state.Auth.authData) {
        return this.$store.state.Auth.authData.email;
      }
      return null;
    },
    finalManagers() {
      if (!this.managers || this.managers.length == 0) {
        return [];
      }
      return this.managers.filter((item) => item.email !== this.email);
    },
  },
  watch: {
    group() {
      this.loadManagers();
    },
  },
  created() {
    this.loadManagers();
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12 border-bottom pb-2 group" :key="group.id">
      <!-- <div class="row"> -->
      <!--   <div class="col-3">Group Name</div> -->
      <!--   <div class="col-6"> -->
      <!--     <input -->
      <!--         class="form-control" -->
      <!--         type="text" -->
      <!--         v-model="group.name" -->
      <!--         @blur="changeGroupName(group, $event)" -->
      <!--         /> -->
      <!--   </div> -->
      <!-- </div> -->

      <div class="row mt-2">
        <div class="col-3">
          Group Managers
          <span class="grey">(Can make bookings for group)</span>
        </div>
        <div class="col-6">
          <template v-for="manager in finalManagers">
            <div
              class="py-2 px-1 mb-1 border-bottom text-start"
              :key="manager.id"
            >
              <div class="row">
                <div class="col">{{ manager.name }} ({{ manager.email }})</div>
                <div class="col">
                  <i
                    title="Remove Manager"
                    class="fa fa-times text-danger removeManager"
                    @click.prevent="removeManager(manager.email)"
                  ></i>
                </div>
              </div>
            </div>
          </template>

          <div class="row">
            <div class="col">
              <input
                class="form-control"
                type="email"
                v-model="newManager"
                id="newManager"
                placeholder="Email: e.g. example@place.com"
              />
            </div>
            <div class="col">
              <button
                class="btn btn-success"
                @click.prevent="addManager(group.name)"
                v-if="newManager"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.grey {
  color: #888;
}
.manager > .col {
  background: #ddd;
}
.removeManager {
  cursor: pointer;
}
</style>
