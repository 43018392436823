<script>
import api from "@/api";
import Vue from "vue";
import ArtistGroup from "@/components/ArtistGroup.vue";
import Loader from "vue-element-loading";

require("jquery-ui/ui/widgets/autocomplete.js");

export default {
  name: "UserProfile",
  components: {
    ArtistGroup,
    Loader,
  },
  data: function () {
    return {
      currentEditingGroupName: null,
      loading: false,
      first_name: null,
      last_name: null,
      phone: null,
      currentPassword: "",
      newPassword: "",
    };
  },
  methods: {
    changeGroupName(group) {
      this.loading = true;
      api.Util.axios
        .patch(`/groups/${group.id}/`, { name: group.name })
        .then(() => {
          Vue.$toast.success("Updated name.");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    removeManager(data) {
      api.Util.axios
        .post("/groupManager/", {
          action: "remove_manager",
          email: data.email,
          groupId: data.groupId,
        })
        .then(({ data }) => {
          if (data.message) {
            this.$store.dispatch("Auth/getGroups");
            alert(data.message);
            this.$store.dispatch("Auth/getGroups");
          }
        });
    },
    addManager(data) {
      api.Util.axios
        .post("/groupManager/", {
          email: data.email,
          groupId: data.groupId,
        })
        .then(({ data }) => {
          if (data.message) {
            alert(data.message);
            this.$store.dispatch("Auth/getGroups");
          }
        });
    },
    changePassword() {
      this.loading = true;
      var data = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };
      api.Util.axios
        .patch(`ProfileInfo/`, data)
        .then(() => {
          alert("Successfully changed password.");
          this.$toast("M");
          this.$store.dispatch("Auth/tryLogin");
        })
        .catch((err) => {
          const message = err.response?.data?.message;
          if (message) {
            alert(message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      var data = {
        first_name: this.first_name,
        last_name: this.last_name,
        phone: this.phone,
      };
      api.Util.axios
        .patch(`ProfileInfo/`, data)
        .then(() => {
          this.$store.dispatch("Auth/tryLogin");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    google_connect() {
      // alert("google_connect");
    },
    facebook_connect() {
      // alert("facebook_connect");
    },
  },
  computed: {
    currentEditingGroup() {
      if (!this.currentEditingGroupName) {
        return null;
      }
      return this.groups.find(
        (group) => group.name === this.currentEditingGroupName
      );
    },
    groupNames() {
      return this.groups.map((group) => group.name);
    },
    groups() {
      return this.$store.state.Auth.groups;
    },
  },
  created: function () {
    this.$store.dispatch("Auth/getGroups");
    const authData = this.$store.state.Auth.authData;
    if (authData) {
      this.first_name = authData.first_name;
      this.last_name = authData.last_name;
      this.phone = authData.phone;
    }
  },
};
</script>

<template>
  <div id="UserProfile" class="container-fluid">
    <Loader :active="loading" />
    <form class="form form-horizontal" action="" @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-md-8 col-12 text-center" id="user_form">
          <h3>You</h3>

          <div class="row justify-content-center">
            <div class="col-10 col-md-3">First Name</div>
            <div class="col-10 col-md-6">
              <input class="form-control" type="text" v-model="first_name" />
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-10 col-md-3">Last Name</div>
            <div class="col-10 col-md-6">
              <input class="form-control" type="text" v-model="last_name" />
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-10 col-md-3">Phone</div>
            <div class="col-10 col-md-6">
              <input class="form-control" type="text" v-model="phone" />
            </div>
          </div>

          <!-- <div class="row"> -->
          <!--     <div class="col-12 col-md-3"> -->
          <!--     </div> -->
          <!--     <div class="col-12 col-md-6"> -->
          <!--         <div class="row justify-content-center"> -->
          <!--             <div class="col-10 col-md-12"> -->
          <!--                 <button class="social-button btn btn-primary disabled form-control" @click.prevent="facebook_connect">Link With Facebook</button> -->
          <!--                 <button class="social-button btn btn-danger disabled form-control" @click.prevent="google_connect">Link with Google</button> -->
          <!--             </div> -->
          <!--         </div> -->
          <!--     </div> -->
          <!-- </div> -->
          <div class="row justify-content-center">
            <div class="col-md-6 col-10">
              <input
                class="form-control btn btn-success"
                type="submit"
                value="Save Changes"
              />
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row justify-content-center">
      <div class="col-md-8 col-12 text-center" id="user_form">
        <h3>Change Password</h3>
        <div class="row justify-content-center">
          <div class="col-10 col-md-3">Current Password</div>
          <div class="col-10 col-md-6">
            <input
              class="form-control"
              type="password"
              v-model="currentPassword"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-10 col-md-3">New Password</div>
          <div class="col-10 col-md-6">
            <input class="form-control" type="password" v-model="newPassword" />
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-6 col-10">
            <button
              class="form-control btn btn-success"
              type="submit"
              :disabled="newPassword.length < 8"
              @click.prevent="changePassword"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-8 col-12 bg-white my-3 p-2 text-center">
        <h3>Groups</h3>
        <div class="row justify-content-center">
          <div class="col-md-6 col-12 text-center">
            <select
              v-model="currentEditingGroupName"
              class="form-control"
              name=""
            >
              <option :value="null">--- Choose a group to edit---</option>
              <template v-for="name in groupNames">
                <option :value="name" :key="name">{{ name }}</option>
              </template>
            </select>
          </div>
        </div>

        <ArtistGroup
          :group="currentEditingGroup"
          v-if="currentEditingGroup"
          @addManager="addManager"
          @removeManager="removeManager"
        />
      </div>

      <div class="col-md-8 col-12" id="saveArea">
        <div class="row justify-content-center">
          <div class="col-md-6 col-10 text-center">
            <router-link class="" :to="{ name: 'GroupRegistration' }">
              Add New Group
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#UserProfile {
  background: #f6f6f6;
  padding-bottom: 20px;
  #user_form,
  .group_form,
  #saveArea {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid rgb(241, 241, 241);
    border-radius: 3px;
    box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
    background: white;
    & > div {
      margin-bottom: 5px;
    }
  }
  .social-button {
    margin-top: 10px;
  }
}
</style>
